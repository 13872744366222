.container{
    background-image: url(./images/bg.jpg);
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover
  }

.title {
    text-align: center;
    color: #EAE9E9;
    padding-top: 60;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 3vw
}

.social-icons {
    display: inline-block;
    margin: auto;
    width: 100%;
    padding-top: 25;
    white-space: nowrap;
    text-align: center;
}

body, html {
    height: 100%;
    margin: 0;
}

